.list-property-form {
    .form-group {
        margin-bottom: 2rem;
    }

    // New progress-based tab design
    .nav-tabs {
        display: flex;
        width: 100%;
        border: none;
        margin: 0 0 2rem;
        position: relative;
        padding: 0;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        border-radius: 0;

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #e0e0e0;
            border-radius: 0;
        }

        .nav-item {
            flex: 1;
            text-align: center;
            position: relative;
            margin: 0;
            padding: 0;

            &:first-child .nav-link {
                border-radius: 0;
            }

            &:last-child .nav-link {
                border-radius: 0;
            }

            .nav-link {
                border: none;
                padding: 0.5rem;
                color: #64748b;
                font-weight: 500;
                background-color: transparent;
                font-size: 0.875rem;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                position: relative;
                text-decoration: none;
                margin: 0;
                min-height: 36px;
                transition: color 0.2s ease;
                border-radius: 0;

                .step-indicator {
                    width: 18px;
                    height: 18px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: inherit;
                    position: relative;

                    svg {
                        font-size: 14px;
                    }

                    .step-number {
                        position: absolute;
                        top: -3px;
                        right: -3px;
                        background-color: #e2e8f0;
                        color: #64748b;
                        width: 11px;
                        height: 11px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 8px;
                        font-weight: 600;
                    }
                }

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: transparent;
                    transition: background-color 0.2s ease;
                }

                &:hover:not(.active):not(.completed) {
                    color: #334155;
                }

                &.active {
                    color: #0ea5e9;

                    &::after {
                        background-color: #0ea5e9;
                    }

                    .step-number {
                        background-color: #0ea5e9;
                        color: white;
                    }
                }

                &.completed {
                    color: #22c55e;

                    &::after {
                        background-color: #22c55e;
                    }

                    .step-number {
                        background-color: #22c55e;
                        color: white;
                    }
                }
            }
        }
    }

    // Redesigned form sections
    .form-section-card {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
        padding: 25px;
        margin-bottom: 30px;

        .section-header {
            margin-bottom: 20px;
            padding-bottom: 15px;
            border-bottom: 1px solid #e9ecef;

            h5 {
                color: #212529;
                font-weight: 600;
                margin-bottom: 5px;
            }

            p {
                color: #6c757d;
                margin-bottom: 0;
                font-size: 14px;
            }
        }
    }

    // New field layouts
    .form-control {
        border: 1px solid #ced4da;
        border-radius: 10px;
        padding: 0.75rem 1rem;
        font-size: 15px;
        color: #27173e;
        transition: all 0.2s ease;
        height: 50px;

        &:focus {
            border-color: #017bef;
            box-shadow: 0 0 0 0.2rem rgba(1, 123, 239, 0.25);
        }

        &.textarea {
            height: auto;
            min-height: 120px;
        }
    }

    .form-label {
        font-weight: 500;
        color: #27173e;
        margin-bottom: 0.5rem;
        font-size: 14px;
    }

    // Improved tag input
    .react-tags {
        position: relative;

        .react-tags__selected {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;
            padding: 0.75rem;
            min-height: 50px;
            border: 1px solid #ced4da;
            border-radius: 10px;
            background: #fff;
            align-items: center;

            .react-tags__selected-tag {
                background: #017bef;
                color: white;
                border: none;
                border-radius: 20px;
                padding: 0.5rem 1rem;
                margin: 0;
                font-size: 14px;
                display: flex;
                align-items: center;
                gap: 0.5rem;

                .react-tags__remove {
                    color: white;
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    line-height: 1;
                    cursor: pointer;
                    opacity: 0.8;
                    transition: opacity 0.2s ease;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }

        .react-tags__search {
            display: flex;
            align-items: center;
            width: 100%;
            min-height: 40px;

            .react-tags__search-input {
                border: none;
                padding: 0;
                font-size: 15px;
                color: #27173e;
                width: 100%;
                outline: none;
                background: transparent;
                height: 100%;

                input {
                    width: 100%;
                    min-width: 200px;
                    padding: 0;
                    border: none;
                    outline: none;
                    font-size: 15px;
                    color: #27173e;
                    background: transparent;
                    height: 100%;

                    &::placeholder {
                        color: #a9abad;
                        opacity: 0.8;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    // Improved select card (dropdown replacement)
    .selectCard {
        background: #f8f9fa;
        padding: 0.75rem 1rem;
        cursor: pointer;
        transition: all 0.2s ease;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 10px;

        &:hover {
            background: #f1f3f5;
            border-color: #adb5bd;
        }

        .flatText {
            color: #27173e;
            font-weight: 500;
            margin: 0;
            padding: 0;
            font-size: 15px;
            line-height: 1.5;
        }

        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            box-shadow: none !important;
            padding: 0 !important;
            margin: 0 !important;
            color: black !important;
            height: 100%;
            display: flex;
            align-items: center;

            &::after {
                margin-left: 0.5rem;
                display: inline-block;
                vertical-align: middle;
            }
        }
    }

    // Improved co-working space toggle
    .coworking-toggle {
        background-color: #f8f9fa;
        border: 1px solid #ced4da;
        border-radius: 10px;
        padding: 20px;
        transition: all 0.3s ease;

        &.active {
            background-color: rgba(1, 123, 239, 0.05);
            border-color: #017bef;
        }

        .coworking-header {
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;

            .form-check {
                margin: 0;
            }

            .form-check-input {
                width: 20px;
                height: 20px;
                cursor: pointer;

                &:checked {
                    background-color: #017bef;
                    border-color: #017bef;
                }
            }

            .form-check-label {
                font-weight: 500;
                color: #27173e;
                cursor: pointer;
            }
        }

        .coworking-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease;

            &.show {
                max-height: 100px;
            }
        }
    }

    // Button styling with loading state
    .button {
        background: #017bef;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
        transition: all 0.2s ease;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;

        &:hover:not(:disabled) {
            background: darken(#017bef, 5%);
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
            background: #86c4ff;
            cursor: not-allowed;
            box-shadow: none;
            transform: none;
        }

        // Loading spinner styling
        .spinner-border {
            width: 18px;
            height: 18px;
            border-width: 2px;
            margin-right: 10px;
        }
    }

    // Ripple effect for submit button
    .button-ripple {
        position: relative;
        overflow: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            pointer-events: none;
            background-image: radial-gradient(
                circle,
                #fff 10%,
                transparent 10.01%
            );
            background-repeat: no-repeat;
            background-position: 50%;
            -webkit-transform: scale(10, 10);
            -moz-transform: scale(10, 10);
            -ms-transform: scale(10, 10);
            -o-transform: scale(10, 10);
            transform: scale(10, 10);
            opacity: 0;
            -webkit-transition: transform 0.5s, opacity 1s;
            -moz-transition: transform 0.5s, opacity 1s;
            -ms-transition: transform 0.5s, opacity 1s;
            -o-transition: transform 0.5s, opacity 1s;
            transition: transform 0.5s, opacity 1s;
        }

        &:active:after {
            -webkit-transform: scale(0, 0);
            -moz-transform: scale(0, 0);
            -ms-transform: scale(0, 0);
            -o-transform: scale(0, 0);
            transform: scale(0, 0);
            opacity: 0.3;
            -webkit-transition: 0s;
            -moz-transition: 0s;
            -ms-transition: 0s;
            -o-transition: 0s;
            transition: 0s;
        }
    }

    // Progress indicator for submit button
    .submit-progress {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            height: 3px;
            width: 0%;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 3px;
            transition: width 0.5s ease;
        }

        &.loading::before {
            width: 100%;
            animation: pulse 1.5s infinite;
        }

        @keyframes pulse {
            0% {
                opacity: 0.6;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0.6;
            }
        }
    }

    .button-calm {
        background: #f4f6f8;
        color: #27173e;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 10px;
        font-weight: 500;
        transition: all 0.2s ease;

        &:hover {
            background: darken(#f4f6f8, 5%);
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        margin-top: 30px;
    }

    // Amenities tab styling
    .amenity-group {
        margin-bottom: 30px;

        .amenity-group-title {
            font-weight: 600;
            color: #017bef;
            margin-bottom: 15px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e9ecef;
            display: flex;
            align-items: center;
        }

        .amenity-checkbox {
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            .form-check-input {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                cursor: pointer;

                &:checked {
                    background-color: #017bef;
                    border-color: #017bef;
                }
            }

            .form-check-label {
                font-size: 15px;
                cursor: pointer;
                margin-bottom: 0;
            }
        }
    }

    // Enhanced upload box styling
    .uploadBox {
        background-color: #f8f9fa;
        border: 2px dashed #ced4da;
        border-radius: 10px;
        padding: 40px 20px;
        text-align: center;
        transition: all 0.3s ease;
        cursor: pointer;
        margin-bottom: 20px;
        position: relative;

        &:hover {
            border-color: #017bef;
            background-color: rgba(1, 123, 239, 0.05);
        }

        &.dragging {
            border-color: #017bef;
            border-width: 3px;
            background-color: rgba(1, 123, 239, 0.1);
            -webkit-box-shadow: 0 0 15px rgba(1, 123, 239, 0.2);
            -moz-box-shadow: 0 0 15px rgba(1, 123, 239, 0.2);
            box-shadow: 0 0 15px rgba(1, 123, 239, 0.2);
            transform: scale(1.01);

            .upload-content {
                transform: scale(1.05);

                p:first-of-type {
                    font-weight: 500;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        .upload-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            position: relative;
            z-index: 2;
            transition: transform 0.3s ease;

            p {
                margin-bottom: 0;
                font-size: 16px;
                color: #495057;
            }

            .button {
                padding: 10px 20px;
                font-size: 15px;
                min-width: 170px;
                position: relative;
                overflow: hidden;
                transition: all 0.3s ease;

                &::after {
                    content: "Click to browse files";
                    position: absolute;
                    bottom: -22px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 12px;
                    color: #6c757d;
                    white-space: nowrap;
                    opacity: 0;
                    transition: opacity 0.3s ease, bottom 0.3s ease;
                }

                &:hover::after {
                    opacity: 1;
                    bottom: -25px;
                }

                &:active {
                    transform: scale(0.95);
                }
            }

            .text-muted {
                font-size: 14px;
                margin-top: 10px;
            }
        }

        // Make entire box clickable
        input[type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
            z-index: 0;
        }
    }

    // Styling for no-images message
    .no-images-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #f8f9fa;
        border: 1px solid #ced4da;
        border-radius: 10px;
        padding: 40px 20px;
        text-align: center;

        p {
            margin-top: 15px;
            margin-bottom: 0;

            &:first-of-type {
                font-size: 16px;
                color: #495057;
            }

            &.text-muted {
                font-size: 14px;
                margin-top: 5px;
            }
        }
    }

    // Loading state styling
    .loading-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 40px 20px;

        p {
            margin-top: 15px;
            color: #017bef;
        }
    }

    .gallery-container {
        margin: 2rem 0;

        .sortable-gallery-wrapper {
            padding: 10px;
            background: #f8f9fa;
            border-radius: 10px;
            border: 1px solid #ced4da;
        }

        // Fix for consistent image thumbnails
        .react-photo-gallery--gallery {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;

            img {
                object-fit: cover !important;
                height: 100% !important;
                width: 100% !important;
                border-radius: 6px;
                box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
                transition: all 0.2s ease;
            }
        }
    }

    .map-container {
        height: 400px !important;
        width: 100% !important;
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        margin: 20px 0;

        > div {
            height: 100% !important;
            width: 100% !important;
        }
    }

    .gmap_canvas {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 400px;

        > div {
            height: 100% !important;
            width: 100% !important;
        }
    }

    .mapouter {
        position: relative;
        text-align: right;
        width: 100%;
        height: 400px;
        margin: 20px 0;

        #map-container {
            height: 400px !important;
            width: 100% !important;
        }
    }

    .error-alert {
        background: #ff396f;
        color: white;
        border-radius: 10px;
        padding: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .close {
            color: white;
            opacity: 0.8;
            cursor: pointer;
        }
    }

    .spinner-border {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    // Custom dropdown styling
    .custom-dropdown-container {
        position: relative;
        width: 100%;
    }

    .custom-dropdown {
        position: relative;
        width: 100%;
        height: 50px;
        border: 1px solid #ced4da;
        border-radius: 10px;
        cursor: pointer;
        background-color: #f8f9fa;
        transition: all 0.2s ease;

        &:hover,
        &.active {
            background-color: #f1f3f5;
            border-color: #adb5bd;
        }

        &.active {
            border-color: #017bef;
            box-shadow: 0 0 0 0.2rem rgba(1, 123, 239, 0.25);

            .dropdown-icon {
                transform: rotate(180deg);
            }

            .dropdown-menu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                z-index: 1000;
            }
        }

        .dropdown-selected {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px;
            height: 100%;

            span {
                font-size: 15px;
                font-weight: 500;
                color: #27173e;
            }

            .dropdown-icon {
                font-size: 12px;
                transition: transform 0.3s ease;
                color: #6c757d;
            }
        }

        .dropdown-menu {
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            width: 100%;
            background: white;
            border: 1px solid #ced4da;
            border-radius: 10px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
            opacity: 0;
            visibility: hidden;
            transform: translateY(-10px);
            transition: opacity 0.3s ease, transform 0.3s ease,
                visibility 0.3s ease;
            z-index: -1;

            &.show {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                z-index: 1000;
            }

            .dropdown-item {
                padding: 12px 15px;
                cursor: pointer;
                transition: background 0.2s ease;

                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }

                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }

                &:hover {
                    background: #f8f9fa;
                }

                &.active {
                    background: rgba(1, 123, 239, 0.1);
                    color: #017bef;
                    font-weight: 500;
                }
            }
        }
    }
}

// Dark mode styles
body.dark-mode {
    .list-property-form {
        .form-section-card {
            background-color: #161129;
            border-color: #2d1f3b;
        }

        .nav-tabs {
            &::after {
                background-color: #2d1f3b;
            }

            .nav-item {
                .nav-link {
                    color: #8f82a5;

                    .step-indicator {
                        background-color: #2d1f3b;
                        color: #8f82a5;
                    }

                    &.active {
                        color: #017bef;

                        .step-indicator {
                            background-color: rgba(1, 123, 239, 0.2);
                        }
                    }
                }
            }
        }

        .form-control {
            background: #161129;
            border-color: #2d1f3b;
            color: #8f82a5;
        }

        .selectCard {
            background: #161129;
            border-color: #2d1f3b;

            .flatText {
                color: #8f82a5;
            }

            &:hover {
                background: #1e1735;
            }
        }

        .coworking-toggle {
            background-color: #161129;
            border-color: #2d1f3b;

            &.active {
                background-color: rgba(1, 123, 239, 0.1);
            }
        }

        // Amenities tab styling
        .amenity-group {
            .amenity-group-title {
                color: #017bef;
                border-bottom-color: #2d1f3b;
            }

            .amenity-checkbox {
                .form-check-label {
                    color: #8f82a5;
                }
            }
        }

        .uploadBox {
            background-color: #161129;
            border-color: #2d1f3b;

            &:hover,
            &.dragging {
                background-color: rgba(1, 123, 239, 0.1);
                border-color: #017bef;
            }

            &.dragging {
                -webkit-box-shadow: 0 0 15px rgba(1, 123, 239, 0.3);
                -moz-box-shadow: 0 0 15px rgba(1, 123, 239, 0.3);
                box-shadow: 0 0 15px rgba(1, 123, 239, 0.3);
            }

            .upload-content {
                p {
                    color: #8f82a5;
                }

                .button::after {
                    color: #6b5f7d;
                }
            }
        }

        .no-images-message {
            background-color: #161129;
            border-color: #2d1f3b;

            p {
                &:first-of-type {
                    color: #8f82a5;
                }
            }
        }

        .custom-dropdown {
            background-color: #161129;
            border-color: #2d1f3b;

            &:hover,
            &.active {
                background-color: #1e1735;
                border-color: #017bef;
            }

            .dropdown-selected {
                span {
                    color: #8f82a5;
                }
            }

            .dropdown-menu {
                background-color: #161129;
                border-color: #2d1f3b;
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);

                .dropdown-item {
                    color: #8f82a5;

                    &:hover {
                        background-color: #1e1735;
                    }

                    &.active {
                        background-color: rgba(1, 123, 239, 0.2);
                        color: #017bef;
                    }
                }
            }
        }
    }
}
